import { addDays, addMinutes } from 'date-fns';
import { OrderItem, Price } from './order.types';
import {
    type AgebandPricingData,
    BookingStatus,
    ComputePriceBookingsResFinancialsPriceMainPrice1Dto,
    FlatPricingData,
    GetBookingsResItemDto,
    GetOrdersResItemDto,
    OrderStatus,
    PaxData,
    PricingType,
    UpdateOrderReqDto,
} from '../../requests';
import { getBookingDtoToBooking } from '../booking/booking.converters';
import { convertNameDtoToFullName } from '../user/user.converters';
import { AgeBands } from '../common.types';
import {
    convertActivityDtoToActivity,
    convertDateToTimestamp,
    getStatusChangeReason,
} from '../common.converters';
import { PaymentStatus } from '../booking/booking.types';
import { UseOrdersData } from './order.hooks';

export const priceDtoToPrice = (
    price: FlatPricingData | AgebandPricingData,
    pax: PaxData
): Price => {
    if (price.type === FlatPricingData.type.PER_PRODUCT) {
        return {
            type: PricingType.PER_PRODUCT,
            count: 1,
            base: price.base_amount,
            unitPrice: price.unit_amount,
        };
    }

    if (price.type === FlatPricingData.type.PER_PERSON) {
        return {
            type: PricingType.PER_PERSON,
            count: Object.values(pax).reduce(
                (sum, ageBand) => sum + ageBand,
                0
            ),
            base: price.base_amount,
            unitPrice: price.unit_amount,
        };
    }

    const { base_amount: base, ...other } =
        price as ComputePriceBookingsResFinancialsPriceMainPrice1Dto;
    return {
        type: PricingType.PER_AGEBAND,
        base,
        ...(Object.keys(other) as (keyof typeof other)[]).reduce(
            (obj, ageBand) =>
                other[ageBand]
                    ? {
                          ...obj,
                          [ageBand]: {
                              count: (pax[ageBand as AgeBands] || 0) as number,
                              unitPrice: other[ageBand],
                          },
                      }
                    : obj,
            {}
        ),
    };
};

export const patchOrderToOrderDto = (
    order: Partial<OrderItem>
): UpdateOrderReqDto => ({
    financials: order.financials,
});

export const getOrderItemDtoToOrder = (
    order: GetOrdersResItemDto
): OrderItem => ({
    id: order.id,
    number: order.number,
    createdBy: convertNameDtoToFullName(order.created.by.name),
    participants: {
        customer: {
            id: order.customer.id,
            fullName: order.customer.name
                ? convertNameDtoToFullName(order.customer.name)
                : '',
            numbers:
                order.customer.contact_details?.phone_numbers?.map(number => ({
                    value: `${number.calling_code} ${number.number}`,
                })) || [],
            emails:
                order.customer.contact_details?.emails?.map(value => ({
                    value,
                })) || [],
        },
    },
    permissions: {
        canEdit: !!order.access.permissions?.update,
        canDelete: !!order.access.permissions?.delete,
    },
    financials: order.price
        ? {
              status: order.bookings?.some(
                  b =>
                      b.financials?.debt.original &&
                      b.status !== BookingStatus.CANCELLED
              )
                  ? PaymentStatus.NOT_PAID
                  : PaymentStatus.PAID,
              total: order.price.total || 0,
              subtotal: order.price.subtotal || 0,
              discount: order.price.discount,
          }
        : undefined,
    status: order.status,
    bookingsCount: order.summary?.counts.bookings || 0,
    productsCount: order.summary?.counts.products || 0,
    travelersCount: order.summary?.counts.customers || 0,
    reason:
        order.status === OrderStatus.CANCELLED
            ? getStatusChangeReason(order.lifecycle, OrderStatus.CANCELLED)
            : undefined,
    bookings:
        order.bookings?.map(v =>
            getBookingDtoToBooking(v as GetBookingsResItemDto)
        ) || [],
    lifecycle: order.lifecycle?.map(convertActivityDtoToActivity) || [],
});

export const orderFilterToRequestBodyConverter = (
    filters: Record<string, any>
): UseOrdersData => ({
    statuses: filters.statuses,
    text_search: filters.searchText || undefined,
    ...((!!filters.createdAt || !!filters.createdBy) && {
        action: 'created',
        at_start: filters.createdAt?.startDate
            ? convertDateToTimestamp(filters.createdAt.startDate, true)
            : undefined,
        at_end: filters.createdAt?.endDate
            ? convertDateToTimestamp(
                  addMinutes(addDays(filters.createdAt.endDate, 1), -1),
                  true
              )
            : undefined,
        by: filters.createdBy || undefined,
    }),
    ...((!!filters.updatedAt || !!filters.updatedBy) && {
        action: 'last_updated',
        at_start: filters.updatedAt?.startDate
            ? convertDateToTimestamp(filters.updatedAt.startDate, true)
            : undefined,
        at_end: filters.updatedAt?.endDate
            ? convertDateToTimestamp(
                  addMinutes(addDays(filters.updatedAt.endDate, 1), -1),
                  true
              )
            : undefined,
        by: filters.updatedBy || undefined,
    }),
    ...((!!filters.deletedAt || !!filters.deletedBy) && {
        action: 'deleted',
        at_start: filters.deletedAt?.startDate
            ? convertDateToTimestamp(filters.deletedAt.startDate, true)
            : undefined,
        at_end: filters.deletedAt?.endDate
            ? convertDateToTimestamp(
                  addMinutes(addDays(filters.deletedAt.endDate, 1), -1),
                  true
              )
            : undefined,
        by: filters.deletedBy || undefined,
    }),
});
