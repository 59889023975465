import React, { useMemo, useState } from 'react';
import { Box, Chip, Collapse, Divider, Stack, Typography } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    BookingStatus,
    EventItem as IEventItem,
    EventStatuses,
    ProductType,
    useCancelEvent,
} from '@travelity/api';
import { Card, Heading, IconButton, IconButtonGroup, Tag } from '@travelity/ui';

import ExpandIcon from '@mui/icons-material/Expand';
import { useTranslation } from 'react-i18next';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleIcon from '@mui/icons-material/Circle';
import WarningIcon from '@mui/icons-material/Warning';
import CloseIcon from '@mui/icons-material/Close';

import { useSnackbar } from 'notistack';

import PerfectScrollbar from 'react-perfect-scrollbar';
import _ from 'lodash';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { EventStatus as EventStatusComponent } from '../event-status';
import { ProductTypeIcon } from '../product-type-icon';
import { paxTotal, useExpand } from '../../hooks';
import { EventBookingItem } from '../event-booking-item';
import { EventEnd } from '../event-end/event-end';
import { ReasonModal } from '../booking-item/components/reason-modal';
import { OverlayWithReason } from '../overlay-with-reason';
import { useResourcePermissions, useResourceType } from '../../contexts/user';
import { OperationStatusItem } from './components/operation-status-item';

export interface EventItemProps {
    event: IEventItem;
    isSelected: boolean;
    bookingId?: string;
    refetch: () => void;
    onPrintClick: () => void;
}

const EventItem: React.FC<EventItemProps> = ({
    event,
    isSelected,
    bookingId,
    refetch,
    onPrintClick,
}) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [deletingEvent, setDeletingEvent] = useState<string>();
    const [expanded, toggleExpand] = useExpand<boolean>();
    const { mutate: cancelEvent, isLoading: isRemoveEventLoading } =
        useCancelEvent({
            onSuccess: () => {
                setDeletingEvent(undefined);
                refetch();
                enqueueSnackbar(
                    `Cancelled the event for the product "${event.product.name}"`,
                    {
                        variant: 'success',
                    }
                );
            },
            onError: () => {
                enqueueSnackbar(
                    `Failed to cancel the event for the product "${event.product.name}"`,
                    {
                        variant: 'error',
                    }
                );
            },
        });
    const cantDelete = useMemo(() => {
        return event.bookings.some(booking => {
            return (
                booking.status === BookingStatus.CONFIRMED ||
                booking.status === BookingStatus.HOLD
            );
        });
    }, [event]);

    const options = event.productOptions.map(o => ({
        name: o.name,
        count: paxTotal(o.pax),
    }));

    const staffAndAssets = useMemo(() => {
        const staffGrouped = _.groupBy(event.staff, 'position');
        return [
            ...Object.entries(staffGrouped).map(([position, staff]) => ({
                name: t(position, { ns: 'common' }),
                type: 'staff',
                value: position,
                count: staff.filter(s => s.reference).length,
            })),
            ...(event.assets.length
                ? [
                      {
                          name: 'Vehicle',
                          type: 'asset',
                          value: 'vehicle',
                          count: event.assets.filter(asset => asset.reference)
                              .length,
                      },
                  ]
                : []),
        ];
    }, [event]);
    const supportsStaff = useResourceType('team');

    const attentionNeeded = useMemo(() => {
        return (
            event.bookings.some(booking => booking.financials.debt > 0) ||
            (supportsStaff && staffAndAssets.some(s => s.count === 0))
        );
    }, [event.bookings, staffAndAssets, supportsStaff]);
    const permissions = useResourcePermissions('event');
    const bookingPermissions = useResourcePermissions('booking');

    const bookings = bookingPermissions.read ? event.bookings : [];

    return (
        <Box>
            <Stack
                direction="row"
                justifyContent="space-between"
                sx={{
                    mt: 2,
                    mb: 1,
                    pr: 5,
                    minWidth: 0,
                }}
            >
                <Stack
                    direction="row"
                    gap={1.5}
                    alignItems="center"
                    sx={{
                        minWidth: 0,
                    }}
                >
                    <Heading
                        sx={{
                            fontSize: '14px',
                            fontWeight: '600',
                            pr: 1,
                        }}
                        alpha={0.7}
                        ellipsis
                    >
                        {event.product.name}
                    </Heading>
                    <CircleIcon sx={{ fontSize: '8px', color: '#D9D9D9' }} />
                    <ProductTypeIcon
                        type={event.product.type}
                        shared={event.product.shared}
                    />
                    <Typography
                        sx={{
                            ml: '-8px',
                            color: '#6B748C',
                            fontSize: '12px',
                            fontWeight: '500',
                            whiteSpace: 'nowrap',
                            pr: 1.5,
                        }}
                    >
                        {event.product.type === ProductType.TOUR
                            ? `${event.product.shared ? 'Group' : 'Private'} `
                            : ''}
                        {t(event.product.type, { ns: 'product' })}
                    </Typography>
                </Stack>
                <Stack
                    direction="row"
                    gap={1}
                    sx={{
                        filter:
                            event.status === EventStatuses.CANCELLED
                                ? 'blur(2px)'
                                : undefined,
                    }}
                >
                    <EventStatusComponent status={event.status} />
                    <EventEnd date={event.date} />
                </Stack>
            </Stack>
            <Card
                isSelected={isSelected}
                sx={{
                    zIndex: 3,
                }}
                parentProps={{ alignItems: 'stretch' }}
                leftAdornment={
                    <Box>
                        {event.status !== EventStatuses.CANCELLED &&
                            event.ephemeral && (
                                <Chip
                                    label="Virtual"
                                    sx={{
                                        width: 1,
                                        borderRadius: '8px',
                                        background: '#EC8031',
                                        padding: '6px 8px',
                                        color: '#FFF',
                                        fontSize: '14px',
                                        mb: 0.5,
                                    }}
                                />
                            )}
                        <Box
                            sx={{
                                width: '88px',
                                height: '88px',
                                p: 1,
                                borderRadius: '12px',
                                bgcolor: '#FFF',
                                boxShadow:
                                    '0px 0px 16px 0px rgba(178, 185, 205, 0.50)',
                            }}
                        >
                            <Stack
                                sx={{
                                    height: 1,
                                    p: 1,
                                    borderRadius: '8px',
                                    bgcolor: '#F4F6FA',
                                }}
                                justifyContent="center"
                                alignItems="center"
                                gap={0.5}
                            >
                                {event.status === EventStatuses.CANCELLED ? (
                                    <CloseIcon
                                        sx={{
                                            fontSize: '36px',
                                            color: '#B2B9CD',
                                        }}
                                    />
                                ) : attentionNeeded ? (
                                    <>
                                        <WarningIcon
                                            sx={{
                                                color: '#EC8031',
                                                fontSize: '24px',
                                            }}
                                        />
                                        <Typography
                                            sx={{
                                                fontSize: '12px',
                                                color: '#B2B9CD',
                                            }}
                                        >
                                            Attention
                                        </Typography>
                                    </>
                                ) : (
                                    <>
                                        <CheckCircleIcon
                                            sx={{
                                                color: '#2CAC60',
                                                fontSize: '24px',
                                            }}
                                        />
                                        <Typography
                                            sx={{
                                                fontSize: '12px',
                                                color: '#B2B9CD',
                                            }}
                                        >
                                            All Set
                                        </Typography>
                                    </>
                                )}
                            </Stack>
                        </Box>
                    </Box>
                }
                buttons={
                    <>
                        <IconButtonGroup>
                            <IconButton
                                href={`/events/${event.id}`}
                                icon={<VisibilityIcon fontSize="small" />}
                                tooltip="Preview"
                            />
                            <Divider sx={{ mx: 0.75 }} />
                            <IconButton
                                onClick={onPrintClick}
                                icon={<SaveAltIcon fontSize="small" />}
                                tooltip="Download"
                            />
                            {event.status !== EventStatuses.CANCELLED &&
                                permissions.delete && (
                                    <>
                                        <Divider sx={{ mx: 0.75 }} />
                                        <IconButton
                                            hoverColor="error.main"
                                            onClick={() =>
                                                setDeletingEvent(event.id)
                                            }
                                            disabled={cantDelete}
                                            icon={
                                                <DeleteIcon fontSize="small" />
                                            }
                                            tooltip="Cancel"
                                        />
                                    </>
                                )}
                        </IconButtonGroup>
                        {!!bookings.length && (
                            <IconButtonGroup>
                                <IconButton
                                    onClick={() => toggleExpand(true)}
                                    icon={<ExpandIcon fontSize="small" />}
                                    tooltip={expanded ? 'Collapse' : 'Expand'}
                                />
                            </IconButtonGroup>
                        )}
                    </>
                }
            >
                <Stack
                    sx={{
                        pb: 0,
                        pt: 1,
                        filter:
                            event.status === EventStatuses.CANCELLED
                                ? 'blur(2px)'
                                : undefined,
                    }}
                >
                    <Stack
                        direction="row"
                        gap={1}
                        sx={{
                            px: 2,
                            py: 1,
                        }}
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Typography sx={{ color: '#B7BCC7', fontSize: '12px' }}>
                            Info badges
                        </Typography>
                        <Stack
                            direction="row"
                            flexGrow={2}
                            gap={1.5}
                            justifyContent="flex-end"
                            alignItems="center"
                        >
                            {!!event.overbooked && (
                                <Box
                                    sx={{
                                        pr: 1.5,
                                        borderRight: '#DFE2EC 1px solid',
                                    }}
                                >
                                    <Tag
                                        sx={{
                                            bgcolor: '#EC8031',
                                            color: '#FFF',
                                        }}
                                        valueProps={{
                                            sx: {
                                                bgcolor: '#FFF',
                                                color: '#EC8031',
                                            },
                                        }}
                                        label="Overbooked"
                                        value={`${event.overbooked}`}
                                    />
                                </Box>
                            )}
                            <Tag
                                label="Total PAX"
                                value={`${event.totalPax}`}
                            />
                            <Tag label="Vacant" value={`${event.vacant}`} />
                        </Stack>
                    </Stack>
                    <Divider sx={{ mt: 1 }} />

                    <Box
                        component={PerfectScrollbar}
                        sx={{
                            '&&': { height: '62px' },
                            pt: 2,
                            pb: 2,
                            top: 0,
                            left: 0,
                            right: 0,
                            zIndex: 1,
                        }}
                        option={{
                            suppressScrollY: true,
                        }}
                    >
                        <Stack
                            direction="row"
                            gap={1}
                            sx={{
                                px: 2,
                                height: 1,
                            }}
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Stack
                                direction="row"
                                gap={1}
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <Typography
                                    sx={{
                                        color: '#B7BCC7',
                                        fontSize: '12px',
                                        whiteSpace: 'nowrap',
                                    }}
                                >
                                    Product Options
                                </Typography>
                                <Stack
                                    direction="row"
                                    gap={0.5}
                                    sx={{
                                        px: 2,
                                        filter:
                                            event.status ===
                                            EventStatuses.CANCELLED
                                                ? 'blur(2px)'
                                                : undefined,
                                    }}
                                >
                                    {options.map(option => (
                                        <Tag
                                            label={option.name}
                                            key={option.name}
                                            values={[`${option.count}`]}
                                        />
                                    ))}
                                </Stack>
                            </Stack>
                        </Stack>
                    </Box>
                </Stack>
                {!!staffAndAssets?.length && (
                    <>
                        <Divider />
                        <Stack
                            direction="row"
                            gap={0.5}
                            sx={{
                                px: 2,
                                py: 1.5,
                                filter:
                                    event.status === EventStatuses.CANCELLED
                                        ? 'blur(2px)'
                                        : undefined,
                            }}
                        >
                            {staffAndAssets.map(
                                ({ name, count, type, value }) => (
                                    <OperationStatusItem
                                        key={name}
                                        name={name}
                                        count={count}
                                        type={type}
                                        value={value}
                                        disabled={!permissions.update}
                                        eventId={event.id}
                                        allowOperation={supportsStaff}
                                    />
                                )
                            )}
                        </Stack>
                    </>
                )}
                {event.status === EventStatuses.CANCELLED && (
                    <OverlayWithReason
                        title="Cancelled"
                        reason={event.reason}
                    />
                )}
            </Card>
            <Collapse in={!!expanded}>
                <Stack gap={1} sx={{ mt: 1 }}>
                    {bookings.map(booking => (
                        <EventBookingItem
                            key={booking.id}
                            booking={booking}
                            isSelected={booking.id === bookingId}
                            eventId={event.id}
                            refetch={refetch}
                        />
                    ))}
                </Stack>
            </Collapse>
            {!expanded &&
                !!bookings.length &&
                bookings.slice(0, 3).map((b, i) => {
                    return (
                        <Stack
                            direction="row"
                            gap={1}
                            key={b.id}
                            sx={{
                                width: 1,
                                alignItems: 'flex-start',
                            }}
                        >
                            <Box
                                sx={{
                                    width: '88px',
                                }}
                            />
                            <Stack flexGrow={2}>
                                <Box
                                    sx={{
                                        mx: i * 2 + 2,
                                        boxShadow:
                                            '0px 0px 16px 0px rgba(178, 185, 205, 0.50)',
                                        height: '8px',
                                        borderRadius: '0px 0px 12px 12px',
                                    }}
                                />
                            </Stack>

                            <Stack
                                flexShrink={0}
                                direction="column"
                                gap={1}
                                sx={{
                                    width: '34px',
                                }}
                            />
                        </Stack>
                    );
                })}

            <ReasonModal
                title="Are you sure you want to cancel this event?"
                content="Provide the reason for cancelling this event"
                confirmText="Confirm"
                open={!!deletingEvent}
                isLoading={isRemoveEventLoading}
                handleCancel={() => setDeletingEvent(undefined)}
                handleConfirm={reason => {
                    cancelEvent({
                        id: deletingEvent as string,
                        reason,
                    });
                }}
            />
        </Box>
    );
};

export default React.memo(EventItem);
